import React from "react";

import { Routes, Route } from "react-router-dom";
import Adminforms from "../components/Admin/Adminforms";
import ProtectedRoute from "./protectedRoutes";
import Error401 from "../components/Errors/Errors";
import Signin from "../components/Signin";

function Paths() {
  return (
    <Routes>
      <Route exact path="/" element={<Signin />} />
        <Route exact path="/admin" element={<ProtectedRoute element={Adminforms} />} />
    {/*  Error routes  */}
        <Route exact path="/error-401" element={<Error401 />} />

    </Routes>
  );
}

export default Paths;
