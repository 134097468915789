import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Alert, Modal, Button } from "react-bootstrap";
import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import TableForm from "./TableForm";
import { useNavigate } from "react-router-dom";
function AddServices(props) {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [appointments, setAppointments] = useState([]);
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const token = Cookies.get("jwtToken");
      if (!token) {
        navigate("/error-401");
      }
      const response = await axios.get("/admin/services", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAppointments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = new FormData(form);
      const formValues = {
        name: data.get("title"),
        description: data.get("description"),
        // price: data.get("price"),
      };
      console.log(formValues);

      try {
        const token = Cookies.get("jwtToken");
        if (!token) {
          throw new Error("No JWT token found in cookies");
        }
        const response = await axios.post("/admin/add-service", formValues, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 201) {
          fetchData();
          event.target.reset();
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          setError(error.response.data.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    }

    setValidated(true);
  };

  const style = { color: "black", opacity: 0 };
  const [value, setValue] = useState();
  console.log(appointments);

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="formS mt-5 mb-5 w-75"
      >
        <Row>
          <center>
            <h3>die Leistung</h3>
          </center>
        </Row>
        <Row className="mb-3 formN">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>
              Titel <span className={"text-danger"}>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              name="title"
              placeholder="Titel"
            />
            <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 ">
          <Form.Group as={Col} md="12" controlId="validationCustom05">
            <Form.Label>
              Beschreibung <span className={"text-danger"}>*</span>
            </Form.Label>
            <textarea
              className="form-control"
              id="inputGroupPrepend"
              name="description"
              rows={3}
              placeholder="Schreiben Sie eine Beschreibung"
              required
            ></textarea>
          </Form.Group>
        </Row>

        <center>
          <Button variant="success" type={"submit"}>
            Leistung hinzufügen
          </Button>
        </center>
      </Form>

      <TableForm
        headers={["Titel", "Beschreibung"]}
        body={appointments}
        delete_url={"/admin/delete-service"}
        update_url={"/admin/update-service"}
        reload={fetchData}
      />
    </>
  );
}

export default AddServices;
