import React from "react";
import { useState } from "react";
import { Container, Table, Button } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
const TableForm = ({
  headers = [],
  body,
  delete_url,
  update_url,
  reload,
  appointment,
}) => {
  headers = [...headers, "Bearbeiten", "Löschen"]; // Add 'Update' and 'Delete' to headers
  const [editingId, setEditingId] = useState(null);
  const [editData, setEditData] = useState({});
  const navigate = useNavigate();

  const handleUpdate = (id) => {
    setEditingId(id);
    const row = body?.find((row) => row._id === id);
    setEditData(row);
  };

  const handleSave = async (id) => {
    // Save the changes
    const url = `${update_url}/${id}`;
    const token = Cookies.get("jwtToken");
    if (!token) {
      navigate('/error-401');
    }else{

    }
    await axios.put(url, { editData },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    reload();
    // Exit editing mode
    setEditingId(null);


  };
  const handleDelete = async (id) => {
    try {
      const token = Cookies.get("jwtToken");
      if (!token) {
        navigate('/error-401');
      }else{
        const response = await axios.delete(`${delete_url}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
        if (response.status === 200) {
          reload();
        }
      }
      }
    catch (error) {
      console.error(error);
    }

  };

  return (
    <div className={'w-75'}>
      <Table striped bordered hover>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.entries(row).map(([key, value], cellIndex) => {
                if (key !== "_id") {
                  if (editingId === row._id) {
                    return (
                      <td key={cellIndex}>
                        <input
                          value={editData[key]}
                          onChange={(e) =>
                            setEditData({ ...editData, [key]: e.target.value })
                          }
                        />
                      </td>
                    );
                  } else {
                    return <td key={cellIndex}>{value}</td>;
                  }
                }
                return null;
              })}
              <td>
                {editingId === row._id ? (
                  <Button variant="outline-success" className="btn btn-outline-success" onClick={() => handleSave(row._id)}>
                    Speichern
                  </Button>
                ) : (
                  <Button
                    variant="outline-success"
                    className="btn btn-outline-success"
                    onClick={() => handleUpdate(row._id)}
                  >
                    Bearbeiten
                  </Button>
                )}
              </td>
              <td>
                <Button variant="danger" onClick={() => handleDelete(row._id)}>
                Löschen
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableForm;
