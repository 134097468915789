import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Alert, Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import React from "react";
import axios from "axios";
import TableForm from "./TableForm";
import { useNavigate } from "react-router-dom";

function AddAppointment(props) {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [appointments, setAppointments] = useState([]);
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const token = Cookies.get("jwtToken");
      if (!token) {
        navigate("/error-401");
      }

      const response = await axios.get("/admin/appointments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setAppointments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = new FormData(form);
      const formValues = {
        appointment: data.get("appointment"),
      };

      try {
        const token = Cookies.get("jwtToken");
        if (!token) {
          throw new Error("No JWT token found in cookies");
        }

        const response = await axios.post(
          "/admin/add-appointment",
          formValues,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 201) {
          fetchData();
          event.target.reset();
        }
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          setError(error.response.data.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    }

    setValidated(true);
  };
  const style = { color: "black", opacity: 0 };
  const [value, setValue] = useState();
  console.log(appointments);

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="formS mt-5 mb-5 w-75"
      >
        <Row>
          <center>
            <h3>Termin</h3>
          </center>
        </Row>
        <Row className="mb-3 formN align-items-center">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>
              Datum und Uhrzeit <span className={"text-danger"}>*</span>
            </Form.Label>
            <Form.Control
              required
              type="datetime-local"
              name="appointment"
              placeholder="datetime"
            />
            <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <center>
          <Button variant="success" type={"submit"}>
            Termin hinzufügen
          </Button>
        </center>
      </Form>

      <TableForm
        headers={["Termin", "Status"]}
        body={appointments}
        delete_url={"/admin/delete-appointment"}
        update_url={"/admin/update-appointment"}
        reload={fetchData}
      />
    </>
  );
}

export default AddAppointment;
