import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import "./signin.css";
import {Button, Modal, Form, Alert} from "react-bootstrap";
import axios from "axios";

import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

function Signin() {
    const [show, setShow] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLogout = () => {
        Cookies.remove("jwtToken");
        navigate('/');

    }
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setError('');
        }, 5000);
    }, [error]);
    const token = Cookies.get("jwtToken");
    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post("/auth/login", {
                email: username,
                password: password,
            });

            if (response.data.status === "SUCCESS") {
                console.log(response.data.message);
                const token = response.data.data.token; // Replace this with your actual JWT token
                Cookies.set("jwtToken", token, {expires: 7}); // Expires in 7 days
                window.location.href = "/admin";
            } else {
                // Handle login error
                setError(response.data.message);
            }
        } catch (error) {
            // Handle request error
            if(error){
                setError(error.response.data.message);

            }
        }
    };
    return (
        <>
                <div className={'container d-flex flex-column flex-wrap align-items-center justify-content-center'}>
                    <div className={'pt-2'}>
                        <h2>Sign In</h2>
                    </div>
                    <div className="w-100 pb-4 justify-content-center ">
                        <Form onSubmit={handleLogin} className={'p-1'}>
                            <Form.Group controlId="formBasicEmail" >
                                <Form.Label className={'navy'}>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword" className="mt-3">
                                <Form.Label className={'navy'}>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Button variant="success" type="submit" className="mt-3">
                                Login
                            </Button>
                            {error && <Alert className={' mt-4 alert-danger'}>
                                {error}
                            </Alert>}
                        </Form>
                    </div>
                </div>

        </>
    );

}

export default Signin;