import { useState } from "react";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";

import logo from "../../assets/logo.png";
import Newsform from "../Forms/Newsform";
import AddAppointment from "../Forms/AddAppointment";
import AddServices from "../Forms/AddServices";
import "./admincss.css";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
const Adminforms = () => {
  const [selectedForm, setSelectedForm] = useState("news");
  const navigate = useNavigate();
  const handleLogout=()=>{
    Cookies.remove("jwtToken");
    navigate('/');

  }
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary px-lg-5 px-sm-2">
        {/*<Container>*/}
          <Navbar.Brand
              href=""
              className="d-flex justify-content-center align-items-center text-success"
          >
            <img src={logo} className="nav-logo me-2" alt="Logo" />
            <h3 className="mt-2">Praxis am Ring Personal</h3>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav " />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-success text-uppercase fs-5 fw-medium">
              <Nav.Link eventKey="news" className={'nav-color'} onClick={() => setSelectedForm("news")}>
              Neuigkeiten 
              </Nav.Link>
              <Nav.Link
                  eventKey="appointment"
                  onClick={() => setSelectedForm("appointment")}
                  className={'nav-color'}
              >
                Termin 
              </Nav.Link>
              <Nav.Link
                  eventKey="service"
                  onClick={() => setSelectedForm("service")}
                  className={'nav-color'}
              >
                Leistungen 
              </Nav.Link>
              <Nav.Link
                  eventKey="logout"
                  onClick={() => handleLogout()}
                  className={'bi-arrow-bar-right nav-color'}
              >
              </Nav.Link>

            </Nav>
          </Navbar.Collapse>
        {/*</Container>*/}
      </Navbar>
      <div className="d-flex align-items-center flex-row flex-wrap justify-content-between div-appointment">
        <div className="d-flex flex-column  w-100 align-items-center justify-content-center p-4 align-items-center">

          {selectedForm === "news" && <Newsform />}
          {selectedForm === "appointment" && <AddAppointment />}
          {selectedForm === "service" && <AddServices />}
        </div>
      </div>

    </>
  );
};

export default Adminforms;
