// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const jwtToken = Cookies.get('jwtToken');

    return jwtToken ? <Component {...rest} /> : <Navigate to="/error-401" />;
};

export default ProtectedRoute;
